import { Box, Typography } from "@mui/material";

type DisplayErrorProps = {
  error: { param: string; msg: string }[] | string;
};

export const DisplayError = ({ error }: DisplayErrorProps) => {
  return (
    <>
      {Array.isArray(error) && (
        <Typography color="error" variant="body1">
          {error.map((err, i) => (
            <Box key={`key - ${i}`}>
              {err.param} - {err.msg}
            </Box>
          ))}
        </Typography>
      )}
      {!Array.isArray(error) && error !== "" && (
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      )}
    </>
  );
};
