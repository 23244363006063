type DisplayNameProps =
  | {
      firstName?: string | null;
      lastName?: string | null;
    }
  | null
  | undefined;

export function getDisplayName(input: DisplayNameProps = {}) {
  const { firstName = "", lastName = "" } = input ?? {};

  return `${firstName} ${lastName}`.trim();
}
