export const capitalize = (string: string): string => {
  if (string.length > 1) {
    const capitalizedString = string[0].toUpperCase() + string.slice(1);
    return capitalizedString;
  } else if (string.length === 1) {
    return string[0].toUpperCase();
  } else return string;
};

/* Given a string that looks like `isThisRealLife`, returns `Is This Real Life` */
export function capitalizeCamelcaseString(status: string) {
  return status
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .map((word) => word.trim())
    .join(" ");
}
