import createClient from "openapi-fetch";

import { getFirebaseToken } from "shared/firebaseConfig/firebase";

import { paths } from "../../../../backend/common/generated/types/api";

export const DEFAULT_TIMEOUT = 25_000;

export const { GET, POST, PUT, PATCH, DELETE } = createClient<paths>({
  baseUrl: process.env.NEXT_PUBLIC_APIV2_URL,
});

/**
 * Use this type to define the options of service methods.
 * Example: async function sendShopEstimate(options: OpenAPIFetchOptions<typeof POST<"/endpoint/path">>)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type OpenAPIFetchOptions<TFetchFn extends (...args: any) => any> =
  Parameters<TFetchFn>[1];

export async function getAuthenticatedRequestHeaders(
  params: {
    contentType?: string;
    optionalAuthHeaders?: boolean;
  } = {}
): Promise<HeadersInit> {
  const { contentType = "application/json" } = params;
  const token = await getFirebaseToken();

  const headers: HeadersInit = { "Content-Type": contentType };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  } else if (!params?.optionalAuthHeaders) {
    throw new Error("User must be logged in");
  }

  return headers;
}

export async function makeRequest(
  url: Parameters<typeof fetch>[0],
  options: Parameters<typeof fetch>[1]
) {
  try {
    const response = await fetch(url, options);
    return await response.json();
  } catch (error) {
    console.error("Request failed", url, error);
    return { error };
  }
}
