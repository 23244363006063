import { createContext, useContext, useMemo, useState } from "react";

import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { useFlags } from "launchdarkly-react-client-sdk";

import { darkTheme, lightTheme, Mode } from "../theme/default";

const ColorModeContext = createContext<{
  color: Mode;
  toggleColorMode: () => void;
}>({
  color: "light",
  toggleColorMode: () => {},
});

export function useColorMode() {
  return useContext(ColorModeContext);
}

function isMode(value: string | null): value is Mode {
  return value === "light" || value === "dark";
}

export function ColorModeProvider({
  children,
  forceAllowDarkMode,
}: {
  children: React.ReactNode;
  forceAllowDarkMode?: boolean;
}) {
  const { darkBrandon } = useFlags();
  const userPrefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const storedMode = localStorage.getItem("colorMode");
  const canSetDarkMode = darkBrandon || forceAllowDarkMode;
  const initialMode = isMode(storedMode)
    ? storedMode
    : canSetDarkMode && userPrefersDarkMode
      ? "dark"
      : "light";
  const [colorMode, setColorMode] = useState<Mode>(initialMode);

  const theme = useMemo(() => {
    return colorMode === "light" ? lightTheme : darkTheme;
  }, [colorMode]);

  const toggleColorMode = () => {
    setColorMode((prevMode) => {
      const newMode = prevMode === "light" ? "dark" : "light";
      localStorage.setItem("colorMode", newMode);
      return newMode;
    });
  };

  return (
    <ColorModeContext.Provider value={{ color: colorMode, toggleColorMode }}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline enableColorScheme />
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
