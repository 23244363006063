export type Toast = {
  success: (message: string, duration?: number) => void;
  error: (message: string, duration?: number) => void;
  info: (message: string, duration?: number) => void;
  warning: (message: string, duration?: number) => void;
};

export interface SnackbarContextProps {
  toast: Toast;
}

export const DEFAULT_DURATION = 10_000;
