export enum Colors {
  ChartGrey = "#A9B5C6",
  ChartPurple = "#5F5DBA",
  ChartTeal = "#5CC5C4",
  DarkGrey = "#00000099",
  Grey = "#D7DCE5",
  ImageBackground = "#F4F4F4",
  Indigo = "#1F027D",
  Navy = "#19213D",
  OffWhite = "#FCFCFC",
  Red = "#D32F2F",
  LightRed = "#F44336",
  Orange = "#ED6C02",
  LightOrange = "#FFA726",
  Green = "#2E7D32",
  Salmon = "#FF784B",
  Steel = "#8C909E",
  Teal = "#017483",
  Yellow = "#FFCC00",
  White = "#FFFFFF",
  Blue = "#0288D1",
  LightBlue = "#88BEFF",
  WhiteSmoke = "#F5F5F5",
  ShuttleGrey = "#5E6977",
  Purple = "#583BE8",
  LightPurple = "#9B8AF0",
  Portage = "#8A76EF",
}

export enum DarkModeColors {
  Black = "#1E1E1E", // Very dark grey, softer than pure black
}
