import { ReactNode } from "react";

import { Box, Container, Stack } from "@mui/material";

import BuildNumber from "../components/BuildNumber";
import ServiceUpLogo from "../components/logos/ServiceUpLogo";

export function ColumnLayout({
  children,
  showLogo,
}: {
  children: ReactNode;
  showLogo?: boolean;
}) {
  return (
    <Container maxWidth={"xs"}>
      <Stack
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "100vh" }}
      >
        {showLogo && (
          <Box>
            <ServiceUpLogo />
          </Box>
        )}
        {children}
        <BuildNumber />
      </Stack>
    </Container>
  );
}
