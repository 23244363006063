import {
  getFromStorage,
  saveToStorage,
  StorageValueName,
} from "./storageUtils";

export enum Pipelines {
  Advisor = "Advisor",
  SDR = "SDR",
}

export type KanbanPipelineSettingType = Pipelines.Advisor | Pipelines.SDR;

export const KANBAN_PIPELINE_DEFAULT = Pipelines.Advisor;

export const isValidKanbanPipelineType = (
  viewInput: string
): viewInput is KanbanPipelineSettingType => {
  return ["Advisor", "SDR"].includes(viewInput);
};

export const getKanbanPipelineSetting = (): KanbanPipelineSettingType => {
  const settingsKanbanPipeline = getFromStorage(
    StorageValueName.KanbanPipeline
  );
  if (
    !settingsKanbanPipeline ||
    !isValidKanbanPipelineType(settingsKanbanPipeline)
  ) {
    console.debug(
      "Kanban pipeline settings not vaid or not found. Setting as default."
    );
    saveToStorage(StorageValueName.KanbanPipeline, KANBAN_PIPELINE_DEFAULT);
    return KANBAN_PIPELINE_DEFAULT;
  }
  return settingsKanbanPipeline;
};
