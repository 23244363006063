import * as Sentry from "@sentry/react";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const app = initializeApp({
  apiKey: process.env.NEXT_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_AUTHDOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_BASEURL,
  projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
});

export const auth = getAuth(app);
export const storage = getStorage(app);

// a handy helper function for getting the token from firebase
// without having to deal with their truly shitty api.
export const getFirebaseToken = () => {
  if (auth.currentUser) {
    return auth.currentUser.getIdToken();
  }
  return new Promise<string | null>((resolve, _) => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const { email, uid: id, phoneNumber, displayName } = user;
        Sentry.setUser({
          id,
          phoneNumber,
          email: email || undefined,
          displayName,
        });
        user.getIdToken().then((token) => {
          resolve(token);
        });
      } else {
        Sentry.setUser(null);
        resolve(null);
      }
    });
  });
};
