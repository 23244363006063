import { KanbanPipelineSettingType } from "./kanbanPipelineSettingsUtils";

export enum StorageValueName {
  KanbanPipeline = "settingsKanbanPipeline",
}

//Add more expected input types to this value for storage usage
type StorageValue = KanbanPipelineSettingType; // | ... | ... | OtherTypes

export const saveToStorage = (
  storageName: StorageValueName,
  value: StorageValue
) => {
  window.localStorage.setItem(storageName, value);
};

export const getFromStorage = (
  storageName: StorageValueName
): string | null => {
  return window.localStorage.getItem(storageName);
};
