import {
  POST, PUT, DELETE, PATCH, GET,
  DEFAULT_TIMEOUT,
  getAuthenticatedRequestHeaders,
  OpenAPIFetchOptions,
} from "../../services/serviceUtils";

export async function loginWithInvite(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/login/invite">>
) {
  return await POST("/fleet/login/invite", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function transferVehicles(
  options: OpenAPIFetchOptions<typeof PUT<"/fleet/vehicles/transfer">>
) {
  return await PUT("/fleet/vehicles/transfer", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function deleteFleet(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}">>
) {
  return await DELETE("/fleet/{fleetId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function create(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}">>
) {
  return await POST("/fleet/{fleetId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function update(
  options: OpenAPIFetchOptions<typeof PUT<"/fleet/{fleetId}">>
) {
  return await PUT("/fleet/{fleetId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function addExistingUsers(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/addExistingUsers">>
) {
  return await POST("/fleet/{fleetId}/addExistingUsers", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function inviteUser(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/invite">>
) {
  return await POST("/fleet/{fleetId}/invite", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function revokeInvite(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}/invite/{inviteId}">>
) {
  return await DELETE("/fleet/{fleetId}/invite/{inviteId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function resendInvite(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/invite/{inviteId}/resend">>
) {
  return await POST("/fleet/{fleetId}/invite/{inviteId}/resend", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function paymentAndRates(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/paymentAndRates">>
) {
  return await POST("/fleet/{fleetId}/paymentAndRates", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function reactivateVehicle(
  options: OpenAPIFetchOptions<typeof PATCH<"/fleet/{fleetId}/reactivateVehicle">>
) {
  return await PATCH("/fleet/{fleetId}/reactivateVehicle", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function resendShopInvite(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/resendShopInvite">>
) {
  return await POST("/fleet/{fleetId}/resendShopInvite", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function revokeFleetShop(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}/revokeShop">>
) {
  return await DELETE("/fleet/{fleetId}/revokeShop", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createRule(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/rules">>
) {
  return await POST("/fleet/{fleetId}/rules", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function inviteShop(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/shopInvites">>
) {
  return await POST("/fleet/{fleetId}/shopInvites", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function deleteAccount(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}/user">>
) {
  return await DELETE("/fleet/{fleetId}/user", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function removeUser(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}/user/{userId}">>
) {
  return await DELETE("/fleet/{fleetId}/user/{userId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateUserRole(
  options: OpenAPIFetchOptions<typeof PUT<"/fleet/{fleetId}/user/{userId}/role">>
) {
  return await PUT("/fleet/{fleetId}/user/{userId}/role", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function addVehicleToFleet(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/vehicle">>
) {
  return await POST("/fleet/{fleetId}/vehicle", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function upload(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/vehicle/upload">>
) {
  return await POST("/fleet/{fleetId}/vehicle/upload", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function uploadStatus(
  options: OpenAPIFetchOptions<typeof GET<"/fleet/{fleetId}/vehicle/upload/{jobId}/status">>
) {
  return await GET("/fleet/{fleetId}/vehicle/upload/{jobId}/status", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateVehicleInFleet(
  options: OpenAPIFetchOptions<typeof PUT<"/fleet/{fleetId}/vehicle/{vehicleId}">>
) {
  return await PUT("/fleet/{fleetId}/vehicle/{vehicleId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function addVehiclesValidated(
  options: OpenAPIFetchOptions<typeof POST<"/fleet/{fleetId}/vehicles">>
) {
  return await POST("/fleet/{fleetId}/vehicles", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function deleteVehicle(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}/vehicles/{vehicleId}">>
) {
  return await DELETE("/fleet/{fleetId}/vehicles/{vehicleId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function deleteCarImage(
  options: OpenAPIFetchOptions<typeof DELETE<"/fleet/{fleetId}/vehicles/{vehicleId}/deleteUsersVehicleImage">>
) {
  return await DELETE("/fleet/{fleetId}/vehicles/{vehicleId}/deleteUsersVehicleImage", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateNickname(
  options: OpenAPIFetchOptions<typeof PUT<"/fleet/{fleetId}/vehicles/{vehicleId}/nickname">>
) {
  return await PUT("/fleet/{fleetId}/vehicles/{vehicleId}/nickname", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateCarImage(
  options: OpenAPIFetchOptions<typeof PUT<"/fleet/{fleetId}/vehicles/{vehicleId}/uploadUsersVehicleImage">>
) {
  return await PUT("/fleet/{fleetId}/vehicles/{vehicleId}/uploadUsersVehicleImage", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}
