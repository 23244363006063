import { Box, Typography } from "@mui/material";

const version = process.env.NEXT_PUBLIC_VERSION;

export default function BuildNumber() {
  return (
    <>
      {version && (
        <Box
          sx={{
            mt: 2,
            textAlign: "center",
            pb: 1,
          }}
        >
          <Typography variant="caption" color="divider">
            Build {version}
          </Typography>
        </Box>
      )}
    </>
  );
}
