export enum WorkflowType {
  ServiceUp = "serviceup",
  SAAS = "saas",
  Insurance = "insurance",
}

export function isWorkflowType(
  workflowType: unknown
): workflowType is WorkflowType {
  return Boolean(
    workflowType &&
      typeof workflowType === "string" &&
      (workflowType === WorkflowType.SAAS ||
        workflowType === WorkflowType.ServiceUp ||
        workflowType === WorkflowType.Insurance)
  );
}
