import Image from "next/image";

import { useColorMode } from "../../providers/ColorModeProvider";

export default function ServiceUpMobileLogo({
  height = 40,
  width = 40,
}: Readonly<{
  height?: number;
  width?: number;
}>) {
  const { color } = useColorMode();
  const lightModeLogo = "/serviceUpMobileLogoLightMode.svg";
  const darkModeLogo = "/serviceUpMobileLogoDarkMode.svg";
  return (
    <Image
      height={height}
      width={width}
      objectFit="contain"
      src={color === "light" ? lightModeLogo : darkModeLogo}
      alt="ServiceUp Logo"
    />
  );
}
