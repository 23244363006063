interface ReactNativeWebView {
  postMessage: (message: string) => void;
}
export const isReactNativeWebview = (
  window: unknown
): window is Window & { ReactNativeWebView: ReactNativeWebView } => {
  if (typeof window !== "object" || window === null) {
    return false;
  }

  return (
    "ReactNativeWebView" in window &&
    typeof window.ReactNativeWebView === "object" &&
    window.ReactNativeWebView !== null &&
    "postMessage" in window.ReactNativeWebView &&
    typeof window.ReactNativeWebView.postMessage === "function"
  );
};
