import { createContext, ReactNode, useContext, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Alert, IconButton, Snackbar } from "@mui/material";

import { DEFAULT_DURATION, SnackbarContextProps } from "./types";

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined
);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

export const SnackbarProvider = ({ children }: { children: ReactNode }) => {
  const [snackbars, setSnackbars] = useState<
    {
      id: number;
      message: string;
      severity: "success" | "error" | "info" | "warning";
      duration: number;
    }[]
  >([]);

  const toast = useMemo(
    () => ({
      success: (message: string, duration: number = DEFAULT_DURATION) => {
        setSnackbars((prev) => [
          ...prev,
          {
            id: new Date().getTime(),
            message,
            severity: "success",
            duration: duration,
          },
        ]);
      },
      error: (message: string, duration: number = DEFAULT_DURATION) => {
        setSnackbars((prev) => [
          ...prev,
          {
            id: new Date().getTime(),
            message,
            severity: "error",
            duration: duration,
          },
        ]);
      },
      info: (message: string, duration: number = DEFAULT_DURATION) => {
        setSnackbars((prev) => [
          ...prev,
          {
            id: new Date().getTime(),
            message,
            severity: "info",
            duration: duration,
          },
        ]);
      },
      warning: (message: string, duration: number = DEFAULT_DURATION) => {
        setSnackbars((prev) => [
          ...prev,
          {
            id: new Date().getTime(),
            message,
            severity: "warning",
            duration: duration,
          },
        ]);
      },
    }),
    []
  );

  const handleClose = (id: number, event?: { type: string }) => {
    // Prevent closing the snackbar onclick on the screen
    if (event?.type === "click") return;

    setSnackbars((prev) => prev.filter((snackbar) => snackbar.id !== id));
  };

  return (
    <SnackbarContext.Provider value={{ toast }}>
      {children}
      {snackbars.map((snackbar, index) => (
        <Snackbar
          key={snackbar.id}
          open={true}
          autoHideDuration={snackbar.duration}
          onClose={(event) => handleClose(snackbar.id, event)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          style={{ top: `${index * 60}px`, paddingTop: 20 }} // Dynamic top position for multiple snackbars
        >
          <Alert
            severity={snackbar.severity}
            action={
              <IconButton
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={() => handleClose(snackbar.id)}
              >
                <CloseIcon />
              </IconButton>
            }
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      ))}
    </SnackbarContext.Provider>
  );
};
