import { ReactNode, useState } from "react";

import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import Link from "next/link";

export interface SidebarItemProps {
  text: string;
  url: string;
  isSelected: boolean;
  icon: ReactNode;
  iconSx?: SxProps<Theme>;
  isOpen: boolean;
}

const SidebarItem = ({
  text,
  url,
  isSelected,
  icon,
  isOpen,
  iconSx,
}: SidebarItemProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popupOpen = Boolean(anchorEl);
  return (
    <Link href={url}>
      <ListItemButton
        aria-owns={popupOpen ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          minHeight: 48,
          justifyContent: isOpen ? "initial" : "center",
          px: 2.5,
        }}
        selected={isSelected}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isOpen ? 3 : "auto",
            justifyContent: "center",
            ...iconSx,
          }}
        >
          <Box display={"flex"} border={2} borderRadius={1}>
            <Box display={"flex"} m={0.2}>
              {icon}
            </Box>
          </Box>
        </ListItemIcon>
        <ListItemText primary={text} sx={{ opacity: isOpen ? 1 : 0 }} />
      </ListItemButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={!isOpen && popupOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
      >
        <Typography sx={{ p: 1 }}>{text}</Typography>
      </Popover>
    </Link>
  );
};

export default SidebarItem;
