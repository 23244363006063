import Image from "next/image";

import { useColorMode } from "../../providers/ColorModeProvider";

export default function ServiceUpLogo({
  height = 40,
  width = 190,
}: Readonly<{
  height?: number;
  width?: number;
}>) {
  const { color } = useColorMode();
  const lightModeLogo = "/serviceUpMainLogoLightMode.svg";
  const darkModeLogo = "/serviceUpMainLogoDarkMode.svg";
  return (
    <Image
      height={height}
      width={width}
      objectFit="contain"
      src={color === "light" ? lightModeLogo : darkModeLogo}
      alt="ServiceUp Logo"
    />
  );
}
