import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

interface AdditionalProps {
  size: "small" | "medium";
}

const SizeMap = {
  small: {
    width: 32,
    height: 16,
    thumb: {
      width: 11,
      height: 11,
    },
  },
  medium: {
    width: 42,
    height: 26,
    thumb: {
      width: 22,
      height: 22,
    },
  },
};

type ExtendedSwitchProps = SwitchProps & AdditionalProps;

const ServiceUpSwitch = styled((props: ExtendedSwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, size }) => ({
  width: SizeMap[size].width,
  height: SizeMap[size].height,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: SizeMap[size].thumb.width,
    height: SizeMap[size].thumb.height,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default ServiceUpSwitch;
