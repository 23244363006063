import { useEffect, useState } from "react";

import assert from "assert";

import MagicBell, {
  FloatingNotificationInbox,
} from "@magicbell/magicbell-react";
import AssignmentFilled from "@mui/icons-material/Assignment";
import AssignmentIndFilled from "@mui/icons-material/AssignmentInd";
import AssignmentLateFilled from "@mui/icons-material/AssignmentLate";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MapIcon from "@mui/icons-material/Map";
import MenuIcon from "@mui/icons-material/Menu";
import PeopleIcon from "@mui/icons-material/People";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import TurnedInIcon from "@mui/icons-material/TurnedIn";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import { useRouter } from "next/router";

import { UNASSIGNED_ADVISOR_OPTION_VALUE } from "shared/components/input/AdvisorAutocomplete";
import ServiceUpSwitch from "shared/components/input/ServiceupSwitch";
import ServiceUpLogo from "shared/components/logos/ServiceUpLogo";
import ServiceUpMobileLogo from "shared/components/logos/ServiceUpMobileLogo";
import { useGetServiceAdvisorQuery } from "shared/generated/graphql";
import { useAuth } from "shared/providers/AuthProvider";
import { useColorMode } from "shared/providers/ColorModeProvider";

import { getDisplayName } from "../../../../backend/common/utils/user.utils";

import SidebarItem, { SidebarItemProps } from "./SidebarItem";
import EditUserModal from "./modals/EditUserModal";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isMobile",
})<{ isMobile: boolean }>(({ theme, open, isMobile }) => ({
  marginRight: 0,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(!isMobile && {
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
  ...(isMobile && {
    ...(open && {
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }),
}));

type SidebarItemConfig = Pick<
  SidebarItemProps,
  "text" | "url" | "isSelected" | "icon" | "iconSx"
>;

function useSidebarItems(props: {
  open: boolean;
  loggedInAdvisorId: number | null;
}): SidebarItemConfig[] {
  const { open, loggedInAdvisorId } = props;
  const router = useRouter();

  const items: SidebarItemConfig[] = [
    {
      text: "Dashboard",
      url: "/",
      isSelected: router.asPath === "/",
      icon: <DashboardIcon />,
    },
    {
      text: "Kanban Board",
      url: `/kanban${
        loggedInAdvisorId ? `?serviceAdvisor=${loggedInAdvisorId}` : ""
      }`,
      isSelected: router.asPath.includes("/kanban"),
      icon: <ViewKanbanIcon />,
    },
    {
      text: "All Requests",
      url: "/requests",
      isSelected: router.asPath === "/requests",
      icon: <AssignmentFilled />,
    },
  ];

  // Only show My Requests if logged in user is an advisor
  if (loggedInAdvisorId) {
    items.push({
      text: "My Requests",
      url: `/requests?serviceAdvisor=${loggedInAdvisorId}`,
      isSelected: router.asPath.startsWith(
        `/requests?serviceAdvisor=${loggedInAdvisorId}`
      ),
      icon: <AssignmentIndFilled />,
      iconSx: open ? { ml: 3 } : { ml: 0 },
    });
  }

  items.push(
    ...[
      {
        text: "Unassigned",
        url: `/requests?serviceAdvisor=${UNASSIGNED_ADVISOR_OPTION_VALUE}`,
        isSelected: router.asPath.startsWith(
          `/requests?serviceAdvisor=${UNASSIGNED_ADVISOR_OPTION_VALUE}`
        ),
        icon: <AssignmentLateFilled />,
        iconSx: open ? { ml: 3 } : { ml: 0 },
      },
      {
        text: "SU Connect",
        url: `/requests?workflowType=saas`,
        isSelected: router.asPath.startsWith("/requests?workflowType=saas"),
        icon: <SupportAgentOutlinedIcon />,
        iconSx: open ? { ml: 3 } : { ml: 0 },
      },
      {
        text: "Calendar",
        url: "/calendar",
        isSelected: router.asPath === "/calendar",
        icon: <CalendarMonthIcon />,
      },
      {
        text: "Shops",
        url: "/shops",
        isSelected: router.asPath === "/shops",
        icon: <StorefrontIcon />,
      },
      {
        text: "Claims",
        url: "/claims",
        isSelected: router.asPath === "/claims",
        icon: <CheckCircleIcon />,
      },
      {
        text: "Customers",
        url: "/customers",
        isSelected: router.asPath === "/customers",
        icon: <PeopleIcon />,
      },
      {
        text: "Vehicles",
        url: "/vehicles",
        isSelected: router.asPath === "/vehicles",
        icon: <DirectionsCarIcon />,
      },
      {
        text: "Geographies",
        url: "/geographies",
        isSelected: router.asPath === "/geographies",
        icon: <MapIcon />,
      },
      {
        text: "Promo Codes",
        url: "/promos",
        isSelected: router.asPath === "/promos",
        icon: <TurnedInIcon />,
      },
      {
        text: "Fleets",
        url: "/fleets",
        isSelected: router.asPath === "/fleets",
        icon: <BusinessIcon />,
      },

      {
        text: "Manage Tags",
        url: "/tags",
        isSelected: router.asPath === "/tags",
        icon: <LocalOfferIcon />,
      },
    ]
  );

  return items;
}

function MiniDrawer({
  children,
  noPadding,
}: Readonly<{
  children: React.ReactNode;
  noPadding: boolean;
}>) {
  assert(
    process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY,
    "Magic Bell API key is missing"
  );
  const { color, toggleColorMode } = useColorMode();
  const router = useRouter();
  const theme = useTheme();
  const { currentUser, logout } = useAuth();

  const isMobile = useMediaQuery("(max-width: 1000px)");
  const persistedIsOpen: boolean = JSON.parse(
    localStorage.getItem("isSidebarOpen") ?? "false"
  );
  const [open, setOpen] = useState(!isMobile ? persistedIsOpen : false);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);

  // Logged advisor user (useAuth user does not have id from users table)
  const { data, refetch } = useGetServiceAdvisorQuery({
    variables: {
      guid: currentUser?.uid ?? "",
    },
    skip: !currentUser,
  });
  const loggedInUser = data?.users[0];
  const loggedInAdvisorId = loggedInUser?.advisor ? loggedInUser.id : null;
  const sidebarItems = useSidebarItems({ open, loggedInAdvisorId });

  const toggleOpen = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // Store in local storage
  useEffect(() => {
    localStorage.setItem("isSidebarOpen", JSON.stringify(open));
  }, [open]);

  if (!currentUser?.email) return null;

  return (
    <Box sx={{ display: "flex" }}>
      {editUserModalOpen && (
        <EditUserModal
          user={loggedInUser}
          show={editUserModalOpen}
          onClose={setEditUserModalOpen}
          refetch={refetch}
        />
      )}
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            {isMobile ? (
              <MenuIcon sx={{ fontSize: 30 }} />
            ) : (
              <Box ml={-0.7} display={"flex"} justifyContent={"center"}>
                <ServiceUpMobileLogo />
                {!isMobile && (
                  <Box alignSelf={"center"}>
                    <ChevronRightIcon sx={{ display: "flex" }} />
                  </Box>
                )}
              </Box>
            )}
          </IconButton>
          <Box
            ml={open ? 0 : -4}
            display={"flex"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography
              alignSelf={"center"}
              variant="h6"
              noWrap
              component="div"
            />
            <Box display={"flex"} gap={2}>
              <Box mt={1} mr={1}>
                <MagicBell
                  apiKey={process.env.NEXT_PUBLIC_MAGIC_BELL_API_KEY}
                  userEmail={currentUser.email}
                  userExternalId={currentUser?.uid}
                  locale="en"
                  theme={{
                    icon: {
                      borderColor: "#FFFFFF",
                    },
                    footer: {
                      backgroundColor: "#FFFFFF",
                      textColor: "#FFFFFF",
                    },
                  }}
                >
                  {(props) => (
                    <FloatingNotificationInbox
                      width={400}
                      height={500}
                      placement="top-start"
                      hideArrow
                      notificationPreferencesEnabled={false}
                      onNotificationClick={(notification) => {
                        router.push(`/requests/${notification.topic}`);
                      }}
                      {...props}
                    />
                  )}
                </MagicBell>
              </Box>
              <Box>
                <Tooltip
                  title={`${getDisplayName(
                    loggedInUser
                  )} | ${loggedInUser?.email}`}
                >
                  <Avatar
                    alt={getDisplayName(loggedInUser)}
                    src={loggedInUser?.avatarUrl ?? undefined}
                    onClick={(evt) => setUserMenuAnchorEl(evt.currentTarget)}
                    sx={{ cursor: "pointer" }}
                  />
                </Tooltip>
                <Menu
                  anchorEl={userMenuAnchorEl}
                  open={Boolean(userMenuAnchorEl)}
                  onClose={() => setUserMenuAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setUserMenuAnchorEl(null);
                      setEditUserModalOpen(true);
                    }}
                  >
                    <ListItemIcon>
                      <ManageAccountsIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Edit User"} />
                  </MenuItem>
                  <MenuItem onClick={toggleColorMode}>
                    <ListItemIcon>
                      <DarkModeIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Dark Mode"} />
                    <ServiceUpSwitch
                      edge="end"
                      checked={color === "dark"}
                      // No need to implement this, the click is really handled by the menu item
                      onChange={() => void 0}
                      size="small"
                      sx={{ ml: 3 }}
                    />
                  </MenuItem>
                  <MenuItem onClick={async () => await logout()}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Log Out"} />
                  </MenuItem>
                </Menu>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        hidden={!open && isMobile}
        variant="permanent"
        open={open}
        isMobile={isMobile}
      >
        <DrawerHeader>
          <Box width={"100%"} display={"flex"} justifyContent={"center"} ml={4}>
            <ServiceUpLogo width={120} height={50} />
          </Box>
          <IconButton onClick={toggleOpen}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon color="secondary" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {sidebarItems.map(({ text, icon, iconSx, url, isSelected }) => (
            <SidebarItem
              key={text}
              text={text}
              url={url}
              isSelected={isSelected}
              icon={icon}
              iconSx={iconSx}
              isOpen={open}
            />
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: noPadding ? 0 : 3 }}
        width={open ? `calc(100% - ${drawerWidth}px)` : "100%"}
        height={noPadding ? "100vh" : "98vh"}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

export default function SideBar({
  children,
  disabled,
  noPadding,
}: Readonly<{
  children: React.ReactNode;
  disabled: boolean;
  noPadding: boolean;
}>) {
  return (
    <>
      {disabled ? (
        <>{children}</>
      ) : (
        <MiniDrawer noPadding={noPadding}>{children}</MiniDrawer>
      )}
    </>
  );
}
