import {
  POST, GET,
  DEFAULT_TIMEOUT,
  getAuthenticatedRequestHeaders,
  OpenAPIFetchOptions,
} from "../../services/serviceUtils";

export async function createAdvisorTokenFromUser(
  options: OpenAPIFetchOptions<typeof POST<"/auth/advisors/generateToken">>
) {
  return await POST("/auth/advisors/generateToken", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createShopImpersonationToken(
  options: OpenAPIFetchOptions<typeof POST<"/auth/advisors/shopImpersonationToken">>
) {
  return await POST("/auth/advisors/shopImpersonationToken", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createAdvisorToken(
  options: OpenAPIFetchOptions<typeof POST<"/auth/advisors/token">>
) {
  return await POST("/auth/advisors/token", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createFleetToken(
  options: OpenAPIFetchOptions<typeof POST<"/auth/fleets/token">>
) {
  return await POST("/auth/fleets/token", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function forgotPassword(
  options: OpenAPIFetchOptions<typeof POST<"/auth/forgot-password">>
) {
  return await POST("/auth/forgot-password", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function logout(
  options: OpenAPIFetchOptions<typeof POST<"/auth/logout">>
) {
  return await POST("/auth/logout", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function getCurrentUser(
  options: OpenAPIFetchOptions<typeof GET<"/auth/me">>
) {
  return await GET("/auth/me", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function resetPassword(
  options: OpenAPIFetchOptions<typeof POST<"/auth/reset-password">>
) {
  return await POST("/auth/reset-password", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createShopToken(
  options: OpenAPIFetchOptions<typeof POST<"/auth/shops/token">>
) {
  return await POST("/auth/shops/token", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createVerificationTokens(
  options: OpenAPIFetchOptions<typeof GET<"/auth/verifications">>
) {
  return await GET("/auth/verifications", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}
