import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

import { getFirebaseToken } from "shared/firebaseConfig/firebase";

export function createApolloClient(
  hasuraRole: string,
  shouldAddHasuraRole = true
) {
  const authLink = setContext(async (_, { headers }) => {
    const token = await getFirebaseToken();
    if (!token) return { headers };

    const extraHeaders = shouldAddHasuraRole
      ? { "X-Hasura-Role": hasuraRole }
      : {};
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
        ...extraHeaders,
      },
    };
  });

  const httpLink = authLink.concat(
    createHttpLink({ uri: process.env.NEXT_PUBLIC_HASURA_URL })
  );

  let link = httpLink;

  if (typeof window !== "undefined") {
    const websocketLink = new GraphQLWsLink(
      createClient({
        url: process.env.NEXT_PUBLIC_HASURA_SOCKET,
        connectionParams: async () => {
          const token = await getFirebaseToken();
          return {
            headers: {
              "X-Hasura-Role": hasuraRole,
              authorization: token ? `Bearer ${token}` : "",
            },
          };
        },
      })
    );

    link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      websocketLink,
      httpLink
    );
  }

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link,
    cache: new InMemoryCache(),
  });
}
