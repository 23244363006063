const PARTS_PRICE_MATRIX = [
  { max: 200, multiplier: 6.66 },
  { max: 500, multiplier: 5.0 },
  { max: 1000, multiplier: 4.0 },
  { max: 4000, multiplier: 3.33 },
  { max: 7500, multiplier: 2.85 },
  { max: 10000, multiplier: 2.5 },
  { max: 15000, multiplier: 2.22 },
  { max: 75000, multiplier: 1.85 },
  { max: Number.MAX_VALUE, multiplier: 1.54 },
];

const isDiscountExpired = (discountInfo, compareDate = new Date()) => {
  return (
    discountInfo?.expiresAt && new Date(discountInfo.expiresAt) < compareDate
  );
};
exports.isDiscountExpired = isDiscountExpired;

const getDiscountAmount = (
  discountInfo,
  discountBasis,
  compareDate = new Date()
) => {
  if (!discountInfo || !discountBasis) {
    return 0;
  }

  const { value = 0 } = discountInfo;

  if (value <= 0 || isDiscountExpired(discountInfo, compareDate)) {
    return 0;
  }

  const { type } = discountInfo;

  if (type === "percentage") {
    return discountBasis * (value / 100);
  }

  if (type === "dollars") {
    return value * 100;
  }

  return 0;
};
exports.getDiscountAmount = getDiscountAmount;

/**
 * getLaborPrice
 * @param {import("../../src/requests/request.entity").ServiceItem} serviceItem
 * @returns {number} The price of labor service item (no taxes)
 */
const getLaborPrice = (serviceItem) => {
  if (
    serviceItem.type === "labor" ||
    serviceItem.type === "body" ||
    serviceItem.type === "paint" ||
    serviceItem.type === "paintMaterials"
  ) {
    return Number(serviceItem.cost);
  }

  return 0;
};
exports.getLaborPrice = getLaborPrice;

/**
 * getServiceItemPrice
 * @param {import("../../src/requests/request.entity").ServiceItem} serviceItem
 * @returns {number} The price of the service item (no taxes)
 */
const getServiceItemPrice = (serviceItem) => {
  if (
    serviceItem.type === "labor" ||
    serviceItem.type === "body" ||
    serviceItem.type === "paint" ||
    serviceItem.type === "paintMaterials"
  ) {
    return Number(serviceItem.cost * serviceItem.quantity);
  }

  if (serviceItem.type === "part") {
    return getPartPrice(serviceItem);
  }

  if (serviceItem.type === "discount") {
    return Number(serviceItem.cost * -1);
  }

  return Number(serviceItem.cost * serviceItem.quantity);
};
exports.getServiceItemPrice = getServiceItemPrice;

const calculateLaborTotal = (service, filterFunction) => {
  const total = service.serviceItems
    .filter(filterFunction)
    .reduce((acc, si) => acc + Number(si.cost * si.quantity), 0);
  return Number(total) || 0;
};

/**
 * serviceLaborTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @returns {number} The total price of the service labor items (no taxes)
 */
const serviceLaborTotal = (service) => {
  const total = calculateLaborTotal(
    service,
    (item) =>
      item.type === "labor" ||
      item.type === "body" ||
      item.type === "paint" ||
      item.type === "paintMaterials"
  );

  return Number(total) || 0;
};
exports.serviceLaborTotal = serviceLaborTotal;

const serviceMechanicalLaborTotal = (service) =>
  calculateLaborTotal(service, (item) => item.type === "labor");

const serviceBodyLaborTotal = (service) =>
  calculateLaborTotal(service, (item) => item.type === "body");
exports.serviceBodyLaborTotal = serviceBodyLaborTotal;

const servicePaintLaborTotal = (service) =>
  calculateLaborTotal(service, (item) => item.type === "paint");
exports.servicePaintLaborTotal = servicePaintLaborTotal;

const servicePaintMaterialsPartTotal = (service) =>
  calculateLaborTotal(service, (item) => item.type === "paintMaterials");
exports.servicePaintMaterialsPartTotal = servicePaintMaterialsPartTotal;

/**
 * getPartPriceEach
 * @param {import("../../src/requests/request.entity").ServiceItem} serviceItemPart
 * @returns {number} The price of an individual service item part (no taxes)
 */
const getPartPriceEach = (serviceItemPart) => {
  const { cost, applyMultiplier, multiplier } = serviceItemPart;
  if (applyMultiplier === false) {
    return cost;
  }

  return (
    cost *
    (multiplier ??
      PARTS_PRICE_MATRIX.filter((x) => cost <= x.max)[0].multiplier)
  );
};
exports.getPartPriceEach = getPartPriceEach;

/**
 * getPartPrice
 * @param {import("../../src/requests/request.entity").ServiceItem} serviceItemPart
 * @returns {number} The total price of a service item part * quantity (no taxes)
 */
const getPartPrice = (serviceItemPart) => {
  return (
    (serviceItemPart.price ?? getPartPriceEach(serviceItemPart)) *
    serviceItemPart.quantity
  );
};
exports.getPartPrice = getPartPrice;

/**
 * servicePartsTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @returns {number} The total price of the service part items (no taxes)
 */
const servicePartsTotal = (service) => {
  const totalParts = service.serviceItems
    .filter((si) => si.type === "part")
    .reduce((acc, si) => acc + getPartPrice(si), 0);
  return Number(totalParts) || 0;
};
exports.servicePartsTotal = servicePartsTotal;

/**
 * serviceFeesTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @returns {number} The total price of the service fee items (no taxes)
 */
const serviceFeesTotal = (service) => {
  const total = service.serviceItems
    .filter((si) => si.type === "fee")
    .reduce((acc, si) => acc + Number(si.cost * si.quantity), 0);

  return Number(total) || 0;
};
exports.serviceFeesTotal = serviceFeesTotal;

/**
 * serviceDiscountTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @returns {number} The total price of the discount service items (no taxes)
 */
const serviceDiscountTotal = (service) => {
  const totalDiscount = service.serviceItems
    .filter((si) => si.type === "discount")
    .reduce((acc, si) => acc + Number(si.cost), 0);
  return Number(totalDiscount) || 0;
};
exports.serviceDiscountTotal = serviceDiscountTotal;

/**
 * serviceTaxTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @param {object} taxes
 * @returns {number} The total taxes of the service
 */
const serviceTaxTotal = (
  service,
  {
    taxRate = 0,
    taxRateLaborCollisionRepair = 0,
    taxRatePaintSupplyMaterial = 0,
  }
) => {
  if (!taxRate && !taxRateLaborCollisionRepair && !taxRatePaintSupplyMaterial) {
    return 0;
  }

  let total = 0;
  if (taxRate) {
    const partsTotal = servicePartsTotal(service);
    total += Math.round(partsTotal * (Number(taxRate) / 100));
  }
  if (taxRateLaborCollisionRepair) {
    const bodyTotal = serviceBodyLaborTotal(service);
    total += Math.round(
      bodyTotal * (Number(taxRateLaborCollisionRepair) / 100)
    );
  }
  if (taxRatePaintSupplyMaterial) {
    const paintMaterialsTotal = servicePaintMaterialsPartTotal(service);
    total += Math.round(
      paintMaterialsTotal * (Number(taxRatePaintSupplyMaterial) / 100)
    );
  }

  return Number(total) || 0;
};
exports.serviceTaxTotal = serviceTaxTotal;

/**
 * serviceSubTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @returns {number} The subtotal of the service (no taxes)
 */
const serviceSubTotal = (service) => {
  return (
    serviceLaborTotal(service) +
    servicePartsTotal(service) +
    serviceFeesTotal(service)
  );
};
exports.serviceSubTotal = serviceSubTotal;

/**
 * serviceTotal
 * @param {import("../../src/requests/request.entity").Service} service
 * @param {object} taxes
 * @returns {number} The total of the service (with taxes)
 */
const serviceTotal = (
  service,
  { taxRate, taxRateLaborCollisionRepair, taxRatePaintSupplyMaterial } = {}
) => {
  return (
    serviceSubTotal(service) +
    serviceTaxTotal(service, {
      taxRate,
      taxRateLaborCollisionRepair,
      taxRatePaintSupplyMaterial,
    })
  );
};
exports.serviceTotal = serviceTotal;
/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved total for the request (with taxes)
 */
exports.total = (request) => {
  return subTotal(request) + taxTotal(request);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The sub total for the request (no taxes)
 */
const subTotal = (request) => {
  return laborTotal(request) + partsTotal(request) + feesTotal(request);
};
exports.subTotal = subTotal;
/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The labor total for the request (no taxes)
 */
const laborTotal = (request) => {
  return request.services
    .map((service) => serviceLaborTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};
exports.laborTotal = laborTotal;
/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The mechanical labor total for the request (no taxes)
 */
exports.mechanicalLaborTotal = (request) => {
  return request.services
    .map((service) => serviceMechanicalLaborTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The body labor total for the request (no taxes)
 */
exports.bodyLaborTotal = (request) => {
  return request.services
    .map((service) => serviceBodyLaborTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The paint labor total for the request (no taxes)
 */
exports.paintLaborTotal = (request) => {
  return request.services
    .map((service) => servicePaintLaborTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The paint materials total for the request (no taxes)
 */
const paintMaterialsTotal = (request) => {
  return request.services
    .map((service) => servicePaintMaterialsPartTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};
exports.paintMaterialsTotal = paintMaterialsTotal;

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The parts total for the request (no taxes)
 */
const partsTotal = (request) => {
  return request.services
    .map((service) => servicePartsTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};

exports.partsTotal = partsTotal;

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The fees total for the request (no taxes)
 */
const feesTotal = (request) => {
  return request.services
    .map((service) => serviceFeesTotal(service))
    .reduce((acc, cur) => acc + cur, 0);
};
exports.feesTotal = feesTotal;

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The tax total for the request/estimate's services
 */
const taxTotal = (request) => {
  const taxRate = request.taxRate || 0;
  const taxRateLaborCollisionRepair = request.taxRateLaborCollisionRepair || 0;
  const taxRatePaintSupplyMaterial = request.taxRatePaintSupplyMaterial || 0;
  return request.services
    .map((service) =>
      serviceTaxTotal(service, {
        taxRate,
        taxRateLaborCollisionRepair,
        taxRatePaintSupplyMaterial,
      })
    )
    .reduce((acc, cur) => acc + cur, 0);
};
exports.taxTotal = taxTotal;

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The total for all request/estimate's services (with taxes)
 */
exports.requestTotal = (request) => {
  const taxRate = request.taxRate || 0;
  const taxRateLaborCollisionRepair = request.taxRateLaborCollisionRepair || 0;
  const taxRatePaintSupplyMaterial = request.taxRatePaintSupplyMaterial || 0;
  return request.services
    .map((service) =>
      serviceTotal(service, {
        taxRate,
        taxRateLaborCollisionRepair,
        taxRatePaintSupplyMaterial,
      })
    )
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * approvedSubTotal
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved sub total for the request (no taxes)
 */
exports.approvedSubTotal = (request) => {
  return request.services
    .map((service) => (service.approved ? serviceSubTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved total for the request (with taxes)
 */
exports.approvedTotal = (request) => {
  const taxRate = request.taxRate || 0;
  const taxRateLaborCollisionRepair = request.taxRateLaborCollisionRepair || 0;
  const taxRatePaintSupplyMaterial = request.taxRatePaintSupplyMaterial || 0;
  return request.services
    .map((service) =>
      service.approved
        ? serviceTotal(service, {
            taxRate,
            taxRateLaborCollisionRepair,
            taxRatePaintSupplyMaterial,
          })
        : 0
    )
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved labor total for the request (no taxes)
 */
exports.approvedLaborTotal = (request) => {
  return request.services
    .map((service) => (service.approved ? serviceLaborTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved parts total for the request (no taxes)
 */
exports.approvedPartsTotal = (request) => {
  return request.services
    .map((service) => (service.approved ? servicePartsTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved fees total for the request (no taxes)
 */
exports.approvedFeesTotal = (request) => {
  return request.services
    .map((service) => (service.approved ? serviceFeesTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The approved discount total for the request (no taxes)
 */
exports.approvedDiscountTotal = (request) => {
  return request.services
    .map((service) => (service.approved ? serviceDiscountTotal(service) : 0))
    .reduce((acc, cur) => acc + cur, 0);
};

/**
 * @param {import("../../src/requests/request.entity").EstimateWithServices} request - The request object
 * @returns {number} The tax total for the request's approved services
 */
exports.approvedTaxTotal = (request) => {
  const taxRate = request.taxRate || 0;
  const taxRateLaborCollisionRepair = request.taxRateLaborCollisionRepair || 0;
  const taxRatePaintSupplyMaterial = request.taxRatePaintSupplyMaterial || 0;
  return request.services
    .map((service) =>
      service.approved
        ? serviceTaxTotal(service, {
            taxRate,
            taxRateLaborCollisionRepair,
            taxRatePaintSupplyMaterial,
          })
        : 0
    )
    .reduce((acc, cur) => acc + cur, 0);
};

exports.toUSD = (amountInCents) =>
  (amountInCents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
