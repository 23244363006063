import { IsEnum, IsInt, IsOptional, IsString } from "class-validator";

export enum InvoiceValidationStates {
  matches = "matches",
  mismatch = "mismatch",
  missingFromInvoice = "missingFromInvoice",
  missingFromDatabase = "missingFromDatabase",
  notChecked = "notChecked",
}

export enum ShopInvoiceStatus {
  AwaitingInvoice = "awaitingInvoice",
  InvoiceSubmitted = "invoiceSubmitted",
  InvoiceResubmitted = "invoiceResubmitted",
  Processed = "processed",
}

export class ShopInvoiceUpdateBody {
  @IsInt()
  public laborExpense!: number;

  @IsInt()
  public partsExpense!: number;

  @IsInt()
  public otherExpense!: number;

  @IsString()
  @IsOptional()
  public invoiceNumber?: string | null;

  @IsString()
  @IsOptional()
  public invoiceDate?: string | null;

  @IsEnum(ShopInvoiceStatus)
  @IsOptional()
  public status?: ShopInvoiceStatus;

  @IsInt()
  @IsOptional()
  public tax?: number;

  @IsString()
  @IsOptional()
  public note?: string;

  @IsString()
  @IsOptional()
  public paymentType?: string;
}

export function isShopInvoiceStatus(
  status: string
): status is ShopInvoiceStatus {
  return Object.values(ShopInvoiceStatus).includes(status as ShopInvoiceStatus);
}
