import {
  POST, PUT,
  DEFAULT_TIMEOUT,
  getAuthenticatedRequestHeaders,
  OpenAPIFetchOptions,
} from "../../services/serviceUtils";

export async function createUser(
  options: OpenAPIFetchOptions<typeof POST<"/user">>
) {
  return await POST("/user", {
    ...options,
    headers: await getAuthenticatedRequestHeaders({ "optionalAuthHeaders": true }),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createServiceAdvisor(
  options: OpenAPIFetchOptions<typeof POST<"/user/serviceAdvisor">>
) {
  return await POST("/user/serviceAdvisor", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateServiceAdvisor(
  options: OpenAPIFetchOptions<typeof PUT<"/user/serviceAdvisor/{advisorId}">>
) {
  return await PUT("/user/serviceAdvisor/{advisorId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateUserZipcode(
  options: OpenAPIFetchOptions<typeof PUT<"/user/{userGuid}/zipcode">>
) {
  return await PUT("/user/{userGuid}/zipcode", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function updateUser(
  options: OpenAPIFetchOptions<typeof PUT<"/user/{userId}">>
) {
  return await PUT("/user/{userId}", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}

export async function createUserVehicle(
  options: OpenAPIFetchOptions<typeof POST<"/user/{userId}/vehicles">>
) {
  return await POST("/user/{userId}/vehicles", {
    ...options,
    headers: await getAuthenticatedRequestHeaders(),
    signal: AbortSignal.timeout(DEFAULT_TIMEOUT),
  });
}
