// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

export function sentryInit({
  dsn = SENTRY_DSN,
  isServer = false,
  ...config
} = {}) {
  const defaultSettings = {
    dsn,
    tracesSampleRate: 1.0,
    environment: process.env.VERCEL_ENV || process.env.ENVIRONMENT,
  };

  if (isServer) {
    Sentry.init({ ...defaultSettings, ...config });
  } else {
    Sentry.init({
      ...defaultSettings,
      // Record 10% of error-free sessions
      replaysSessionSampleRate: 0.1,
      // Record 100% of sessions with errors
      replaysOnErrorSampleRate: 1.0,
      integrations: [new Sentry.Replay()],
      ...config,
    });
  }
}
