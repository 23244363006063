import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  getKanbanPipelineSetting,
  KANBAN_PIPELINE_DEFAULT,
  KanbanPipelineSettingType,
} from "./kanbanPipelineSettingsUtils";
import { saveToStorage, StorageValueName } from "./storageUtils";

interface Settings {
  kanbanPipeline: KanbanPipelineSettingType;
}

const DEFAULT_SETTINGS: Settings = {
  kanbanPipeline: KANBAN_PIPELINE_DEFAULT,
};

const AccountSettingsContext = createContext<{
  settings: Settings;
  initAccountSettings: () => void;
  saveKanbanPipeline: (pipeline: KanbanPipelineSettingType) => void;
}>({
  settings: DEFAULT_SETTINGS,
  initAccountSettings: () => void 0,
  saveKanbanPipeline: (_pipeline: KanbanPipelineSettingType) => void 0,
});

export function useAccountSettings() {
  return useContext(AccountSettingsContext);
}

export function AccountSettingsProvider({ children }: { children: ReactNode }) {
  const [settings, setSettings] = useState<Settings>(DEFAULT_SETTINGS);

  const initAccountSettings = () => {
    const storedKanbanPipeline: KanbanPipelineSettingType =
      getKanbanPipelineSetting();
    const newSettings: Settings = {
      kanbanPipeline: storedKanbanPipeline,
    };
    console.debug("initializing account settings", newSettings);
    setSettings(newSettings);
  };

  const saveKanbanPipeline = (pipeline: KanbanPipelineSettingType) => {
    saveToStorage(StorageValueName.KanbanPipeline, pipeline);
    setSettings((prev) => {
      return { ...prev, kanbanPipeline: pipeline };
    });
  };

  useEffect(() => {
    initAccountSettings();
  }, []);

  const value = {
    settings,
    initAccountSettings,
    saveKanbanPipeline,
  };

  return (
    <AccountSettingsContext.Provider value={value}>
      {children}
    </AccountSettingsContext.Provider>
  );
}
