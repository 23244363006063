import {
  deleteObject,
  getBlob,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { extension } from "mime-types";
import * as uuid from "uuid";

export const uploadFile: any = async (
  fileSource: Blob,
  location = "images/",
  name?: string,
  onProgress?: (progress: number) => void
) => {
  const storage = getStorage();
  const fileName = name ?? uuid.v4();

  try {
    const storageRef = ref(storage, location + fileName);
    const uploadTask = uploadBytesResumable(storageRef, fileSource);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get progress percentage and call onProgress if it's provided
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          if (onProgress) {
            onProgress(progress);
          }
        },
        (error) => {
          console.error("The image was not properly saved in Firebase.", error);
          reject({ error });
        },
        async () => {
          try {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            resolve({ url });
          } catch (error) {
            console.error("Failed to get download URL.", error);
            reject({ error });
          }
        }
      );
    });
  } catch (error) {
    console.error("An error occurred during the file upload process.", error);
    return { error };
  }
};

export const downloadFile = async (url: string, filename: string) => {
  const storage = getStorage();
  try {
    const completeUrl = await getDownloadURL(ref(storage, url));

    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (_event: unknown) => {
      const blob = xhr.response;
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${filename}.${extension(blob.type)}`; // use mime to get the extension of the file

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    };
    xhr.open("GET", completeUrl);
    xhr.send();
  } catch (error) {
    console.error("The file was not properly downloaded: %o", error);
    alert("The file was not properly downloaded.");
  }
};

export const getFileBlobAndName = async (
  url: string
): Promise<{ blob: Blob; name: string } | undefined> => {
  const storage = getStorage();
  try {
    const storageRef = ref(storage, url);
    const blob = await getBlob(storageRef);
    return { blob, name: storageRef?.name };
  } catch (error) {
    console.error("The file was not properly downloaded: %o", error);
  }
};

export const deleteFile = async (url: string) => {
  const storage = getStorage();
  try {
    await deleteObject(ref(storage, url));
  } catch (error) {
    console.error("The file was not properly deleted: %o", error);
  }
};
