import "../styles/globals.css";
import { ApolloProvider } from "@apollo/client";
import { NoSsr } from "@mui/material";
import { LicenseInfo } from "@mui/x-license";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { NextPage } from "next";
import { AppProps } from "next/app";
import { useRouter } from "next/router";

import { ColumnLayout } from "shared/layouts/ColumnLayout";
import { AuthProvider } from "shared/providers/AuthProvider";
import { ColorModeProvider } from "shared/providers/ColorModeProvider";
import { UnreadRequestsProvider } from "shared/providers/UnreadRequestsProvider";
import { SnackbarProvider } from "shared/toast/SnackbarProvider";

import PageHead from "../components/PageHead";
import SideBar from "../components/SideBar";
import client from "../lib/apollo-client";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import { AccountSettingsProvider } from "../providers/AccountSettings/AccountSettingsProvider";

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY as string);

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string);
const routesRequireLogo = ["/auth/signin", "/auth/invite"];

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();
  const disableAuth =
    router.asPath.startsWith("/auth/") ||
    router.asPath.startsWith("/accept") ||
    router.asPath.startsWith("/approve") ||
    router.asPath.startsWith("/verify") ||
    router.asPath.startsWith("/pay") ||
    router.asPath.startsWith("/terms_and_conditions");

  const inRequestPage = router.asPath.startsWith(
    `/requests/${router.query.requestId}`
  );

  const showColumnLayoutWithLogo = routesRequireLogo.some((route) =>
    router.asPath.includes(route)
  );

  return (
    <NoSsr>
      <PageHead />
      <AuthProvider routeRequiresAuth={disableAuth} loginPath={"/auth/signin"}>
        <ColorModeProvider forceAllowDarkMode>
          <AccountSettingsProvider>
            <ApolloProvider client={client}>
              <Elements stripe={stripePromise}>
                <SnackbarProvider>
                  <SideBar disabled={disableAuth} noPadding={inRequestPage}>
                    <UnreadRequestsProvider>
                      {showColumnLayoutWithLogo ? (
                        <ColumnLayout showLogo={showColumnLayoutWithLogo}>
                          <Component {...pageProps} />
                        </ColumnLayout>
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </UnreadRequestsProvider>
                  </SideBar>
                </SnackbarProvider>
              </Elements>
            </ApolloProvider>
          </AccountSettingsProvider>
        </ColorModeProvider>
      </AuthProvider>
      <Analytics />
      <SpeedInsights />
    </NoSsr>
  );
}

export default withLDProvider<AppProps & JSX.IntrinsicAttributes>({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_KEY as string,
  options: {
    streaming: true,
  },
})(MyApp);
