import Head from "next/head";

export default function PageHead({ title }: { title?: string }) {
  const fullTitle = title
    ? `${title} | ServiceUp for Advisors`
    : "ServiceUp for Advisors";
  return (
    <Head>
      <title>{fullTitle}</title>
    </Head>
  );
}
