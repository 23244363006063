import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";

import {
  Int_Comparison_Exp,
  useGetAllServiceAdvisorsQuery,
} from "../../generated/graphql";
import { Option } from "../../types";

type UseAdvisorAutocompleteProps = {
  selectedAdvisorId: number | null;
};

export const UNASSIGNED_ADVISOR_OPTION_VALUE = -1;

export function AdvisorAutocomplete(
  props: UseAdvisorAutocompleteProps &
    Pick<
      AutocompleteProps<Option<number>, false, false, false>,
      "onChange" | "sx"
    >
) {
  const { data } = useGetAllServiceAdvisorsQuery();

  const advisorUsers =
    data?.advisorUsers.flatMap((advisor) =>
      advisor.user ? advisor.user : []
    ) ?? [];

  const advisorOptions: Option<number>[] = [
    {
      value: UNASSIGNED_ADVISOR_OPTION_VALUE,
      label: "Unassigned",
    },
    ...advisorUsers.map((user) => ({
      value: user.id,
      label: `${user.firstName} ${user.lastName}`,
    })),
  ];

  const selectedAdvisorOption =
    advisorOptions.find((option) => option.value === props.selectedAdvisorId) ??
    null;

  return (
    <Autocomplete
      sx={props.sx}
      options={advisorOptions}
      value={selectedAdvisorOption}
      onChange={props.onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          size="small"
          placeholder="Select Advisor"
          label="Advisor"
        />
      )}
    />
  );
}

export function getAdvisorApiFilter(
  advisorId: number | null
): Int_Comparison_Exp | undefined {
  if (!advisorId) return;
  if (advisorId === UNASSIGNED_ADVISOR_OPTION_VALUE) {
    return { _is_null: true };
  }
  return { _eq: advisorId };
}
